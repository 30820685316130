import AbstractSubject, {
    Observer
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import StayDurationTypeState, { StayDurationType } from '../store/StayDurationTypeState';
import DatePickerCalendar from '../../calendar/webComponents/DatePickerCalendar';

export default class CalendarModalConnector implements Observer {
    readonly #element: HTMLSelectElement;

    constructor(element: HTMLSelectElement) {
        this.#element = element;
    }

    public update(subject: AbstractSubject): void {
        if (!(subject instanceof StayDurationTypeState)) {
            return;
        }

        const type = subject.type;
        const arrivalDepartureCalendar = this.#element.querySelector(
            '[data-role="arrival-departure-calendar"]'
        );

        const flexibleStaySelection = this.#element.querySelector(
            '[data-role="flexible-stay-selection"]'
        );

        const flexibleArrivalDate = this.#element.querySelector(
            'footer.nh-modal__footer section[data-for="flexible-arrival-date"]'
        );

        flexibleArrivalDate?.toggleAttribute('hidden', type === StayDurationType.FLEXIBLE);

        flexibleStaySelection?.toggleAttribute('hidden', type === StayDurationType.CALENDAR);

        arrivalDepartureCalendar?.toggleAttribute('hidden', type === StayDurationType.FLEXIBLE);

        if (type === StayDurationType.CALENDAR) {
            const datepicker = arrivalDepartureCalendar?.querySelector(
                'datepicker-calendar'
            ) as DatePickerCalendar;
            datepicker.scrollToCurrentMonth();
        }
    }

    public static connectToElement(querySelector: string): void {
        const element = document.querySelector(querySelector) as HTMLSelectElement | null;
        const state = StayDurationTypeState.getInstance();

        if (!element) return;

        const connector = new CalendarModalConnector(element);
        state.attach(connector);
    }
}
