import resultTemplate from '@templates/components/molecules/destinations-list.html.njk';
import Anchor from '@naturehouse/design-system/components/atoms/anchor/Anchor';
import {
    matchMediaAddEventListener,
    matchMediaRemoveEventListener
} from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import { debounce } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import HttpClient from '../../../common/HttpClient';
import Modal from '../modal/Modal';
import SearchTrackingEvents from '../../../enums/searchTrackingEvents';
import BigQuery from '../../../common/BigQuery';
import DestinationSearchStore, {
    RecentDestinationSearchItem
} from '../../../modules/search/store/DestinationSearchStore';

type RecentSearchType = {
    content: string;
    url: string;
};

type PossibleDestinationsType = {
    plaatsen?: Record<string, string>[];
    region?: Record<string, string>[];
    natuurgebieden?: Record<string, string>[];
    department?: Record<string, string>[];
    country?: Record<string, string>[];
    popular?: Record<string, string>[];
};

interface RenderDataInterface {
    no_results: boolean;
    keyword: string[];
    plaatsen?: Record<string, string>[];
    region?: Record<string, string>[];
    natuurgebieden?: Record<string, string>[];
    department?: Record<string, string>[];
    country?: Record<string, string>[];
    popular?: Record<string, string>[];
    translations: Record<string, string>;
}

export const enum DestinationSearchEvents {
    DESTINATION_SELECTED = 'destination_selected',
    DESTINATION_CLEARED = 'destination_cleared'
}

/**
 * @deprecated
 */
export default class DestinationSearch extends HTMLElement {
    readonly #input = this.querySelector('input[is="nh-input"]') as HTMLInputElement;

    readonly #modalInput: HTMLInputElement;

    readonly #modal = new Modal({
        title: this.getAttribute('title') || 'Title',
        classList: ['search-form-modal', 'nh-destination-search'],
        hidden: true
    });

    readonly #destinationSearchEndpoint: string;

    readonly #resultsContainer: HTMLElement;

    readonly #inputListener = debounce(this.#handleDestinationSearch.bind(this), 200);

    readonly #translations: Record<string, string>;

    readonly #form: HTMLFormElement;

    readonly #tabletMq = getComputedStyle(document.documentElement).getPropertyValue('--tablet');

    #isTabletOrBigger: boolean;

    readonly #destinationSearchStore = DestinationSearchStore;

    constructor() {
        super();

        this.#modal.dataset.for = 'nh-destination-search';
        this.#modal.dataset.pw = 'nh-destination-search';

        const template = this.querySelector('#destination-search-content') as HTMLTemplateElement;
        if (!template) {
            throw new Error('Template not found');
        }

        const content = document.importNode(template.content, true);
        this.#modalInput = content.querySelector('input[is="nh-input"]') as HTMLInputElement;
        this.#resultsContainer = content.querySelector('[data-role="results"]') as HTMLElement;

        this.#form = this.closest('form') as HTMLFormElement;

        this.#destinationSearchEndpoint = this.dataset.destinationSearchEndpoint || '';
        this.#translations = JSON.parse(this.dataset.translations || '{}');

        this.#isTabletOrBigger = matchMedia(this.#tabletMq).matches;

        this.#modal.content = content;
        this.#init();
    }

    protected connectedCallback(): void {
        matchMediaAddEventListener(
            matchMedia(this.#tabletMq),
            this.#handleMediaQueryChange.bind(this)
        );

        this.addEventListener(
            DestinationSearchEvents.DESTINATION_SELECTED,
            this.#trackSearchEvent.bind(this)
        );
    }

    protected disconnectedCallback(): void {
        matchMediaRemoveEventListener(
            matchMedia(this.#tabletMq),
            this.#handleMediaQueryChange.bind(this)
        );

        this.removeEventListener(
            DestinationSearchEvents.DESTINATION_SELECTED,
            this.#trackSearchEvent.bind(this)
        );
    }

    public clear(): void {
        this.#form.setAttribute('action', this.#form.dataset.defaultAction || '');
        this.#clear();
    }

    #init(): void {
        document.body.append(this.#modal);
        const inputs = [this.#input, this.#modalInput];

        this.#input.addEventListener('keydown', this.#handleKeyDown);

        inputs.forEach((input) => {
            input.addEventListener('input', this.#inputListener);
            input.addEventListener('clear', () => {
                this.clear();

                if (input === this.#modalInput) {
                    return;
                }

                if (this.#isTabletOrBigger && this.#modal.isOpen) {
                    this.#modal.close();
                }

                this.dispatchEvent(new CustomEvent(DestinationSearchEvents.DESTINATION_CLEARED));
            });
            input.addEventListener('change', (event) => event.stopPropagation());
        });

        this.#input.addEventListener('click', (event) => {
            if (
                this.#isTabletOrBigger &&
                this.#input.value.length <= 2 &&
                Object.keys(this.#destinationSearchStore.state).length === 0
            ) {
                this.#modal.close();
                return;
            }

            this.#showModal();
            this.#handleDestinationSearch(event);
            this.#modalInput.focus();
        });
    }

    #clear(): void {
        const inputs = [this.#input, this.#modalInput];
        inputs.forEach((clearInput) => {
            clearInput.value = '';
            clearInput.dispatchEvent(new CustomEvent(DestinationSearchEvents.DESTINATION_CLEARED));
        });

        if (!this.#resultsContainer) {
            return;
        }

        this.#render({
            no_results: false,
            keyword: [],
            translations: this.#translations
        });
    }

    async #handleDestinationSearch(event: Event): Promise<void> {
        if (!this.isConnected) {
            return;
        }

        const inputs = [this.#input, this.#modalInput];
        inputs.forEach((input) => {
            if (event.target === input) return;

            const changedInput = event.target as HTMLInputElement;
            input.value = changedInput.value;
            input.dispatchEvent(new Event('change'));
        });

        if (
            this.#input.value.length <= 2 &&
            this.#isTabletOrBigger &&
            Object.keys(this.#destinationSearchStore.state).length === 0
        ) {
            this.#modal.close();
            return;
        }

        this.#showModal();

        const url: URL = new URL(this.#destinationSearchEndpoint);
        url.searchParams.set('query', this.#input.value);

        const response: StandardObjectInterface = await HttpClient.get(url.href);

        if (!this.#resultsContainer) {
            return;
        }

        const hasNoPossibleDestinations = Array.isArray(response.possibleDestinations);
        const showNoResults = this.#input.value.length > 0 && hasNoPossibleDestinations;
        const showRecent = this.#input.value.length === 0 && hasNoPossibleDestinations;

        const possibleDestinations =
            response && response.possibleDestinations
                ? (response.possibleDestinations as PossibleDestinationsType)
                : {};

        for (const category of Object.values(possibleDestinations)) {
            for (const item of category) {
                const value = item.content ?? '';
                const term = value.split(',')[0];
                item.term = term;
            }
        }

        const data = {
            ...response.possibleDestinations,
            translations: this.#translations,
            keyword: [
                {
                    content: this.#input.value,
                    url: JSON.stringify({ keyword: this.#input.value })
                }
            ],
            no_results: showNoResults,
            recent: showRecent ? this.#getRecentSearches() : null
        };

        this.#render(data);

        this.#handleSelectDestination();

        const houseLinks: Anchor[] = Array.from(
            this.#resultsContainer.querySelectorAll('[is="nh-anchor"]')
        );

        houseLinks.forEach((link) => {
            link.addEventListener('click', this.#trackHouseIdSearch.bind(this));
        });
    }

    #addToSearchHistory(value: RecentDestinationSearchItem): void {
        this.#destinationSearchStore.state = value;
    }

    #getExpiryTimestamp(): number {
        const date = new Date();
        date.setDate(date.getDate() + 30);

        return date.getTime();
    }

    readonly #showModal = (): void => {
        if (this.#modal.isOpen) {
            return;
        }

        this.#modal.open({
            bindToElement: this.#input
        });
    };

    #render(data: RenderDataInterface): void {
        this.#resultsContainer.innerHTML = resultTemplate.render(data);
    }

    #handleMediaQueryChange(event: MediaQueryListEvent): void {
        this.#isTabletOrBigger = event.matches;
    }

    #trackSearchEvent(e: Event): void {
        const event = e as CustomEvent;
        const detail = event.detail;

        const data: GoogleAnalytics4DestinationSearchEvent = {
            value: detail.term ?? '',
            type: detail.type ?? '',
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage',
            term: detail.term
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.DESTINATION,
            eventParams: [
                { eventKey: 'value', eventValue: data.value },
                { eventKey: 'type', eventValue: data.type },
                { eventKey: 'page', eventValue: data.page },
                { eventKey: 'term', eventValue: data.term }
            ]
        });
    }

    #trackHouseIdSearch(e: Event): void {
        const link = e.currentTarget as Anchor;

        const label: HTMLElement | null = link.querySelector('.nh-anchor__label');

        const data: GoogleAnalytics4HouseIdSearchEvent = {
            value: label?.innerText ?? '',
            page: window.location.pathname === '/' ? 'homepage' : 'searchpage',
            term: this.#input.value
        };

        BigQuery.track({
            eventName: SearchTrackingEvents.HOUSE_ID,
            eventParams: [
                { eventKey: 'value', eventValue: data.value },
                { eventKey: 'page', eventValue: data.page },
                { eventKey: 'term', eventValue: data.term }
            ]
        });
    }

    #handleKeyDown(event: KeyboardEvent): void {
        if (event.key !== 'Enter') return;

        event.preventDefault();
    }

    #getRecentSearches(): RecentSearchType[] {
        const values = Object.values(this.#destinationSearchStore.state)
            .filter((item) => item.expiry > new Date().getTime())
            .sort((a, b) => b.expiry - a.expiry)
            .slice(0, 3);

        return values.map((item): any => ({
            content: item.term,
            url: item.isKeywordSearch ? JSON.stringify({ keyword: item.value }) : item.value
        }));
    }

    #handleSelectDestination(): void {
        this.#resultsContainer
            .querySelectorAll<HTMLInputElement>('.nh-chip input')
            .forEach((chip) => {
                chip.addEventListener('change', () => {
                    if (!chip.value) return;

                    const chipLabel = chip.nextElementSibling as HTMLLabelElement;
                    const isKeywordSearch = chip.value.includes('keyword');

                    const inputValue = this.#input.value;

                    this.#input.value = chip.dataset.term || chipLabel.innerText;
                    this.#input.name = isKeywordSearch
                        ? 'keyword'
                        : this.#input.dataset.defaultName || '';

                    const formAction = isKeywordSearch
                        ? this.#form.dataset.defaultAction || ''
                        : chip.value;

                    this.#form.setAttribute('action', formAction);
                    this.#modal.close();

                    const detail = {
                        isKeywordSearch,
                        value: isKeywordSearch ? chipLabel.innerText.trim() : formAction,
                        term: chipLabel.innerText.trim(),
                        inputValue: inputValue,
                        type: isKeywordSearch ? 'keyword' : chip.dataset.type
                    };

                    this.#addToSearchHistory({
                        [detail.value]: {
                            value: detail.value,
                            term: detail.term,
                            isKeywordSearch: detail.isKeywordSearch,
                            expiry: this.#getExpiryTimestamp()
                        }
                    });

                    this.dispatchEvent(
                        new CustomEvent(DestinationSearchEvents.DESTINATION_SELECTED, { detail })
                    );
                });
            });
    }
}

if (!customElements.get('nh-destination-search')) {
    customElements.define('nh-destination-search', DestinationSearch);
}
