import type { DefaultComponentType } from '../../../../src/utils/types';
import './TextToggle.pcss';

type TextToggleOptionProps = DefaultComponentType & {
    label: string;
    value: string;
};

export type TextToggleProps = DefaultComponentType & {
    id: string;
    name: string;
    value: string;
    options: TextToggleOptionProps[];
    disabled?: boolean;
};

export default class TextToggle extends HTMLElement {
    static get observedAttributes(): string[] {
        return ['disabled', 'value'];
    }

    get disabled(): boolean {
        return this.hasAttribute('disabled');
    }

    set value(value: string) {
        if (this.value === value || this.disabled) return;
        this.setAttribute('value', value);
    }

    get value(): string {
        return this.getAttribute('value') || '';
    }

    get #options(): HTMLInputElement[] {
        return Array.from(this.querySelectorAll('input'));
    }

    #updateValue = (event: Event): void => {
        const option = event.target as HTMLInputElement;

        if (!option.checked || this.disabled || option.value === this.value) return;

        this.setAttribute('value', option.value);
    };

    protected attributeChangedCallback(name: string): void {
        if (name === 'disabled') return;

        this.#update();
    }

    protected connectedCallback(): void {
        this.#options.forEach((option): void => {
            option.addEventListener('change', this.#updateValue.bind(this));
        });
        this.#update();
    }

    protected disconnectedCallback(): void {
        this.#options.forEach((option): void => {
            option.removeEventListener('change', this.#updateValue.bind(this));
        });
    }

    #update(): void {
        this.toggleAttribute('aria-disabled', this.disabled);
        this.#options.forEach((option): void => {
            option.toggleAttribute('disabled', this.disabled);
        });

        this.#options.forEach((input) => {
            input.checked = input.value === this.value;
        });
    }
}

if (!window.customElements.get('nh-text-toggle')) {
    window.customElements.define('nh-text-toggle', TextToggle);
}
